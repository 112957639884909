<template>
	<div class="goods-detail">
		<top-search :preselection="1" @changeMode="changeMode"></top-search>
		<div v-loading="info_loading">
			<div class="center_content">
				<div class="left_content">
					<div class="tabs" v-if="goods_info.is_online_shop && goods_info.extend_data">
						<div :class="goods_show ==1 ? 'active':''" @click="goods_show = 1">网店<div v-if="goods_show==1" class="sanjiao" ></div>
						</div>
						<div :class="goods_show ==2 ? 'active':''" @click="goods_show = 2">公司<div v-if="goods_show==2" class="sanjiao"></div>
						</div>
						<div :class="goods_show ==3 ? 'active':''" @click="goods_show = 3">商标<div v-if="goods_show==3" class="sanjiao"></div>
						</div>
					</div>
					<div class="goods_content">
						<div class="goods_img" v-if="goods_show ==1 && goods_info.goods_image">
							<el-carousel height="312px">
								<el-carousel-item v-for="(item,index) in goods_info.goods_image" :key="index">
									<img :class="goods_info.is_online_shop && goods_info.extend_data?'':'allRadius'" :src="$img(item,{size:'big'})" alt="" @error="errorImg('goods',index)">
								</el-carousel-item>
							</el-carousel>
						</div>
						<div class="goods_img" v-if="goods_show ==2 && goods_info.extend_data">
							<el-carousel height="312px">
								<el-carousel-item v-for="(item,index) in goods_info.extend_data.company_image" :key="index">
									<img :src="$img(item,{size:'big'})" alt="" @error="errorImg('company',index)">
								</el-carousel-item>
							</el-carousel>
						</div>
						<div class="goods_img" v-if="goods_show ==3 && goods_info.extend_data">
							<el-carousel height="312px">
								<el-carousel-item v-for="(item,index) in goods_info.extend_data.logo_image" :key="index">
									<img :src="$img(item,{size:'big'})" alt="" @error="errorImg('logo',index)">
								</el-carousel-item>
							</el-carousel>
						</div>
						<div class="goods_info">
							<div class="top_info" v-if="goods_show==1">
								<div class="name">{{goods_info.goods_name}}</div>
								<div class="sub_info">
									<div class="number">编号：{{goods_info.goods_code}}</div>
									<div class="copy" @click="$util.copy(goods_info.goods_code)">复制</div>
									<div class="time">{{$util.timeStampTurnTime(goods_info.modify_time).split(' ')[0].replaceAll('-','/')}}</div>
								</div>
								<div class="goods_tags">
									<div class="tag" v-if="goods_info.agent_member_id != 0">官验</div>
									<div class="tag">{{goods_info.category_name}}</div>
									<div class="tag" v-for="(aItem,aIndex) in goods_info.goods_attr_parse" :key="aIndex" v-if="aItem.label_type == 1">{{aItem.attr_value_name}}</div>
								</div>
								<div class="more_info">
									<div class="accredits">
										<div class="item" v-for="(aItem,aIndex) in goods_info.goods_attr_parse" :key="aIndex" v-if="aItem.label_type != 1">{{aItem.attr_value_name}}</div>
									</div>
									<div class="price">￥<div class="number">{{goods_info.price}}</div>
									</div>
								</div>
							</div>
							<div class="top_info" v-if="goods_show==2">
								<div class="name">{{goods_info.extend_data.company_name}}</div>
								
								<div class="goods_tags">
									<div class="tag" v-if="goods_info.agent_member_id != 0">官验</div>
									<div class="tag">{{goods_info.category_name}}</div>
									<div class="tag" v-for="(aItem,aIndex) in goods_info.extend_data.company_attr_format" :key="aIndex" v-if="aItem.label_type == 1">{{aItem.attr_value_name}}</div>
								</div>
								<div class="more_info">
									<div class="accredits">
										<div class="item" v-for="(aItem,aIndex) in goods_info.extend_data.company_attr_format" :key="aIndex" v-if="aItem.label_type != 1">{{aItem.attr_value_name}}</div>
									</div>
									<div class="price">￥<div class="number">{{goods_info.price}}</div></div>
								</div>
							</div>
							<div class="top_info" v-if="goods_show==3">
								<div class="name">{{goods_info.extend_data.logo_name}}</div>
								
								<div class="goods_tags">
									<div class="tag" v-if="goods_info.agent_member_id != 0">官验</div>
									<div class="tag">{{goods_info.category_name}}</div>
									<div class="tag" v-for="(aItem,aIndex) in goods_info.extend_data.logo_attr_format" :key="aIndex" v-if="aItem.label_type == 1">{{aItem.attr_value_name}}</div>
								</div>
								<div class="more_info">
									<div class="accredits">
										<div class="item" v-for="(aItem,aIndex) in goods_info.extend_data.logo_attr_format" :key="aIndex" v-if="aItem.label_type != 1">{{aItem.attr_value_name}}</div>
									</div>
									<div class="price">￥<div class="number">{{goods_info.price}}</div>
									</div>
								</div>
							</div>
							<div class="bottom_info">
								<div class="left">
									<img class="logo" @click="toMemberDetail(goods_info.member_info.member_id)" v-if="goods_info.member_info" :src="$img(goods_info.member_info.headimg)" @error="goods_info.member_info.headimg = defaultHeadImage" alt="">
									<div class="iconfont1 icon-xihuan" :class="is_collect?'active':''" @click="collectGoods()"></div>
									<img src="@/assets/images/newImg/message.png" alt="" @click="toMessage()">
									<!-- <img src="@/assets/images/newImg/kefu.png" alt="" @click="toViewChat()"> -->
									<img src="@/assets/images/newImg/bargain.png" @click="toBargain()" />
									<div class="iconfont1 icon-gouwuche cart" :class="is_cart?'active':''" @click="cartGoods()"></div>
									<div class="show_deal" v-if="goods_info.label">{{goods_info.label}}</div>
								</div>
								<div class="purchase" v-if="goods_info.goods_state && goods_info.goods_stock>0" @click="buyGoods()">抢购</div>
								<div class="purchase grey" v-else>抢购</div>
							</div>
						</div>
					</div>
				</div>
				<div class="right_content" v-if="goods_info.member_info">
					<div class="top_info">
						<img @click="toMemberDetail(goods_info.member_info.member_id)" :src="$img(goods_info.member_info.headimg)" @error="goods_info.member_info.headimg = defaultHeadImage" alt="">
						<div @click="toMemberDetail(goods_info.member_info.member_id)" class="name">{{goods_info.member_info.nickname}}</div>
						<div class="card" v-if="goods_info.member_info.is_agent">
							<img class="is_agent" src="@/assets/images/newImg/isAgent.png" />
							经纪人
						</div>
					</div>
					<div class="btns">
						<div class="member_tit" @click="toMemberDetail(goods_info.member_info.member_id)">主页</div>
						<div class="btn" @click="toViewChat()">客服</div>
						<div class="wx btn">
							微信
							<img v-if="goods_info.member_info.wechat_personal_qrcode" class="wechat_img" :src="$img(goods_info.member_info.wechat_personal_qrcode)" alt="">
						</div>
					</div>
				</div>
			</div>
			<div class="flow">
				<div class="price_info" v-if="goods_info.fee_data && goods_info.fee_data.length">
					<div class="item" v-for="(item,index) in goods_info.fee_data">
						{{item.title}}：<div class="number">{{item.price}}</div>
					</div>
					
					<div class="item">
						<div class="circle">
							<div></div>
						</div>
						总费用：<div class="number">{{fee_total_price}}</div>
					</div>
				</div>
				<div class="flow_info">
					<div class="item" v-for="(item,index) in trade_progress" :key="index">
						<div class="top">
							<div class="flex_base">
								<div class="line" v-if="index != 0"></div>
							</div>
							<div class="circle">
								<div class="center">
									<div></div>
								</div>
							</div>
							<div class="flex_base">
								<div class="line" v-if="index != trade_progress.length-1"></div>
							</div>
						</div>
						<div class="text">{{item}}</div>
					</div>
				</div>
			</div>
			<div class="detail_content" id="detail_content">
				<div class="tabs">
					<div class="item" v-for="(item,index) in content_sort" :key="index" :class="{ 'active': detailStatus == item.id }" @click="handleTabs(item.id)">{{item.name}}
						<div class="line"></div>
					</div>
				</div>
				<div class="content" v-if="detailStatus == 'trade_progress'">
					<div v-if="goods_info.goods_content && goods_info.goods_content.trade_progress" v-html="goods_info.goods_content.trade_progress"></div>
					<div class="empty" v-else>暂无信息</div>
				</div>
				<div class="content" v-if="detailStatus == 'give_information'">
					<div v-if="goods_info.goods_content && goods_info.goods_content.give_information" v-html="goods_info.goods_content.give_information"></div>
					<div class="empty" v-else>暂无信息</div>
				</div>
				<div class="content" v-if="detailStatus == 'purchase_obtain'">
					<div class="obtain_area" v-if="goods_info.trademark_info">
						<div class="obtain_item with_img">
							<div class="tit">商标</div>
							<div class="info">
								<el-image :src="$util.img(goods_info.trademark_info.pic)" :preview-src-list="[goods_info.trademark_info.pic]" class="biao_img" ></el-image>
								<!-- preview-src-list -->
							</div>
						</div>
						<div class="obtain_item">
							<div class="tit">商标名</div>
							<div class="info">{{goods_info.trademark_info.name}}</div>
						</div>
						<div class="obtain_item">
							<div class="tit">注册号</div>
							<div class="info">{{goods_info.trademark_info.regno}}</div>
						</div>
						<div class="obtain_item">
							<div class="tit">分类</div>
							<div class="info">第{{goods_info.trademark_info.classid}}类</div>
						</div>
						<div class="obtain_item">
							<div class="tit">商品服务</div>
							<div class="info">{{dealProductlist('name')}}</div>
						</div>
						<div class="obtain_item">
							<div class="tit">类似群</div>
							<div class="info">{{dealProductlist('id')}}</div>
						</div>
						<div class="obtain_item">
							<div class="tit">申请人</div>
							<div class="info">{{goods_info.trademark_info.registrant}}</div>
						</div>
						<div class="obtain_item">
							<div class="tit">地址</div>
							<div class="info">{{goods_info.trademark_info.address}}</div>
						</div>
						<div class="obtain_item">
							<div class="tit">申请日期</div>
							<div class="info">{{goods_info.trademark_info.appdate}}</div>
						</div>
						<div class="obtain_item">
							<div class="tit">专用期限</div>
							<div class="info">{{goods_info.trademark_info.startdate}} - {{goods_info.trademark_info.enddate}}</div>
						</div>
						<div class="obtain_item">
							<div class="tit">状态</div>
							<div class="info">{{goods_info.trademark_info.status}}</div>
						</div>
					</div>
					<template v-else>
						<div v-if="goods_info.goods_content && goods_info.goods_content.purchase_obtain" v-html="goods_info.goods_content.purchase_obtain"></div>
						<div class="empty" v-else>暂无信息</div>
					</template>
				</div>
				<!-- 砍价 -->
				<div class="bargain" v-if="detailStatus == 'bargain'">
					<div class="to_bargain">
						<el-image class="headimg" :src="$img(member.headimg ? member.headimg : defaultHeadImage)" @error="member.headimg = defaultHeadImage" fit="cover"></el-image>
						<div class="input_area">
							<input placeholder="" v-model="bargainPrice" />
							<el-button type="primary" @click="goBargain()">砍价</el-button>
						</div>
					</div>
					<div class="bargin_list" v-if="goodsBargainList.length">
						<div class="bargain-info" v-for="(item,index) in goodsBargainList" :key="item.id">
							<div class="info-left">
								<img @error="item.headimg = defaultHeadImage" :src="$img(item.headimg ? item.headimg : defaultHeadImage )" >
								<span>{{ item.nickname }}</span>
								<p>{{ $util.timeStampTurnTime(item.update_time).split(' ')[0].replaceAll('-','/') }}</p>
							</div>
							<div class="info-main">
								<div>砍价</div>
								<div>
									<span>￥</span>
									<span>{{ item.price }}</span>
								</div>
							</div>
							<div class="info-right">
								<div @click="toViewChat(item.member_id)">消息</div>
								<div>微信
									<img v-if="item.wechat_personal_qrcode" class="wechat_img" :src="$img(item.wechat_personal_qrcode)" alt="">
								</div>
							</div>
						</div>
					</div>
					<!-- <div class="empty" v-else>暂无砍价记录</div> -->
					<div class="pager">
						<el-pagination background :pager-count="5" :total="bargainTotal" layout="prev,pager,next,jumper,total"
							:current-page.sync="bargainCurrentPage" :page-size.sync="bargainPageSize" @size-change="handlePageSizeChange"
							@current-change="handleCurrentPageChange" hide-on-single-page></el-pagination>
					</div>
				</div>
				
				<!--评论  -->
				<div class="evaluate" v-if="detailStatus == 'comment'">
					<div class="evaluate-bottom evaluate-height">
						<el-image class="headimg" :src="$img(member.headimg ? member.headimg : defaultHeadImage)" @error="member.headimg = defaultHeadImage" fit="cover"></el-image>
						<div class="evaluate-bottom-content">
							<div class="evaluate-main">
								<el-input :autosize="{minRows:1,maxRows:2}" type="textarea" v-model="comment" resize="none" placeholder=""></el-input>
								<div class="imgs" >
									<div class="img_item" v-for="(item,index) in imgList" :key="index">
										<el-image :src="$img(item)"  :preview-src-list="imgBigList" fit="cover"></el-image>
										<div @click="deleteImg(index)" class="el-icon-error"></div>
									</div>
								</div>
							</div>
							<div class="evaluate-btns" @click="sendComment()">
								发送
							</div>
						</div>
						<!-- :on-exceed="handleExceed" -->
						<el-upload ref="upload" :data="{token:token}" :show-file-list="false" :action="uploadActionUrl" list-type="picture-card" :on-success=" (file, fileList) => { return handleSuccess(file, fileList); } "   :before-upload=" (file, fileList) => { return uploadBefore(file, fileList); } ">
							<i class="el-icon-circle-plus-outline"></i>
						</el-upload>
					</div>
					<div class="evaluate-info" v-if="commentList.length" v-loading="loading">
						<div class="evaluateItem" v-for="(item,index) in commentList" :key="item.id">
							<div class="evaluate-top">
								<div class="evaluate-top-first">
									<img class="headimg" :src="$img(item.member_headimg ? item.member_headimg : defaultHeadImage)" alt="" @error="item.member_headimg = defaultHeadImage">
									<span>{{ item.member_nickname }}</span>
									<span>{{ $util.timeStampTurnTime(item.create_time).split(' ')[0].replaceAll('-','/') }}</span>
									<p class="action" @click="handleReply(index,item)">{{item.showReply ? '收起' : '回复'}}</p>
									<p class="action" v-if="item.member_id == member.member_id" @click="handleDelete(item,index)">删除</p>
								</div>
								<div class="evaluate-top-second" >
									<div class="content">{{ item.content }}</div>
									<div class="images" v-if="item.images.length">
										<el-image v-for="(itemImg,indexImg) in item.images" :key="indexImg" fit="cover" class="review_img" :src="$img(itemImg)" :preview-src-list="item.imagesList"  alt="" v-if="itemImg" ></el-image>
									</div>
								</div>
								<div class="evaluate-bottom evaluate-height" v-if="item.showReply">
									<div class="evaluate-bottom-content">
										<div class="evaluate-main">
											<el-input :autosize="{minRows:1,maxRows:2}" type="textarea" v-model="replyComment" resize="none" :placeholder="`回复@${Object.keys(replyChild).length ? replyChild.nickname : reply.member_nickname}`"></el-input>
											<div class="imgs" >
												<div class="img_item" v-for="(item,index) in replyImgList" :key="index">
													<el-image :src="$img(item)"  :preview-src-list="replyImgBigList" fit="cover"></el-image>
													<div @click="deleteImg(index)" class="el-icon-error"></div>
												</div>
											</div>
										</div>
										<div class="evaluate-btns" v-if="Object.keys(replyChild).length" @click="confirmReply(replyChild)">
											回复
										</div>
										<div class="evaluate-btns" v-else @click="confirmReply(reply)">
											回复
										</div>
									</div>
									<el-upload ref="upload" :data="{token:token}" :show-file-list="false" :action="uploadActionUrl" list-type="picture-card" :on-success=" (file, fileList) => { return handleSuccess(file, fileList); } "  :before-upload=" (file, fileList) => { return uploadBefore(file, fileList); } ">
										<i class="el-icon-circle-plus-outline"></i>
									</el-upload>
								</div>
							</div>
							<div class="evaluate-content" >
								<div class="replys" v-if="item.show && item.child">
									<div class="replyItem" v-for="replayItem in item.child" :key="replayItem.id" :id="`evaluateRefs${replayItem.id}`">
										<div class="evaluate-content-first">
											<div>
												<img :src="$img(replayItem.headimg ? replayItem.headimg : defaultHeadImage)" alt="" @error="replayItem.headimg = defaultHeadImage">
											</div>
											<span>{{ replayItem.member_nickname }}</span>
											<i class="el-icon-caret-right" v-if=" replayItem.recommend_id != item.id"></i>
											<span v-if=" replayItem.recommend_id != item.id">{{ replayItem.recommend_member_nickname}}</span>
											<span>{{ $util.timeStampTurnTime(replayItem.create_time).split(' ')[0].replaceAll('-','/') }}</span>
											<p class="action" @click="handleReply(index,item,replayItem)">{{replayItem.showReply ? '收起':'回复'}}</p>
											<p class="action" v-if="replayItem.member_id == member.member_id" @click="handleDelete(replayItem,index)">删除</p>
										</div>
										<div class="evaluate-content-third">
											<div class="content">{{replayItem.content}}</div>
											<div class="images" v-if="replayItem.images.length">
												<el-image v-for="replayImg in replayItem.images" :key="replayImg" class="headimg"  :src="$img(replayImg)"  :preview-src-list="replayItem.imagesList" alt="" fit="cover" v-if="replayImg"></el-image>
											</div>
										</div>
										<div class="evaluate-bottom evaluate-height" v-if="replayItem.showReply">
											<div class="evaluate-bottom-content">
												<div class="evaluate-main">
													<el-input :autosize="{minRows:1,maxRows:2}" type="textarea" v-model="replyComment" resize="none" :placeholder="`回复@${Object.keys(replyChild).length ? replyChild.nickname : reply.member_nickname}`"></el-input>
													<div class="imgs" >
														<div class="img_item" v-for="(item,index) in replyImgList" :key="index">
															<el-image :src="$img(item)"  :preview-src-list="replyImgBigList" fit="cover"></el-image>
															<div @click="deleteImg(index)" class="el-icon-error"></div>
														</div>
													</div>
												</div>
												<div class="evaluate-btns" v-if="Object.keys(replyChild).length" @click="confirmReply(replyChild)">
													回复
												</div>
												<div class="evaluate-btns" v-else @click="confirmReply(reply)">
													回复
												</div>
											</div>
											<el-upload ref="upload" :data="{token:token}" :show-file-list="false" :action="uploadActionUrl" list-type="picture-card" :on-success=" (file, fileList) => { return handleSuccess(file, fileList); } " :before-upload=" (file, fileList) => { return uploadBefore(file, fileList); } ">
												<i class="el-icon-circle-plus-outline"></i>
											</el-upload>
										</div>
									</div>
								</div>
								<div class="expand-more" v-if="item.number">
									<div class="show-more">
										<div v-if="item.number && !item.checkbox"  @click="getComments(item,index,comment_ids)">
											<span></span>
											<span>展开{{ item.number }}条回复</span>
											<i class="el-icon-arrow-down"></i>
										</div>
										<div v-else-if="item.replyCurrentPage <= item.replyPageCount && item.checkbox " @click="getComments(item,index,comment_ids)">
											<span></span>
											<span>展开更多</span>
											<i class="el-icon-arrow-down"></i>
										</div>
									</div>
									<div class="pack-up" v-if="item.checkbox" @click="packUp(item,index)">
										<span>收起</span>
										<i class="el-icon-arrow-up"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="pager">
						<el-pagination background :pager-count="5" :total="commentTotal" layout="prev,pager,next,jumper,total"
							:current-page.sync="commentCurrentPage" :page-size.sync="commentPageSize" @size-change="handleCommentPageSizeChange"
							@current-change="handleCommentCurrentPageChange" hide-on-single-page></el-pagination>
					</div>
					<!-- <div class="evaluate-height"></div> -->
				</div>
			</div>
		</div>
		
		<!-- 报价弹框 -->
		<servicerMessage ref="servicerMessage" class="kefu" :toUid="toUid"></servicerMessage>
	</div>
</template>

<script>
import servicerMessage from "@/components/message/servicerMessage"
import topSearch from '@/components/top_search';
import detail from './detail';
export default {
	name: 'detail',
	mixins: [detail],
	components: {
		topSearch,
		servicerMessage
	},
};
</script>
<style lang="scss">
	.goods-detail{
		.pager{
			.el-pagination{
				padding: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				.btn-prev{
					margin-right: 15px !important;
				}
				.btn-next{
					margin-left: 15px !important;
				}
				.btn-prev,.btn-next{
					min-width: 20px;
					height: 20px;
					margin: 0;
					color: #30373D !important;
					font-weight: bolder !important;
					background-color: transparent;
				}
				.el-pagination__jump{
					margin-left: 20px;
					color: #86909C;
					font-size: 14px;
					line-height: 11px;
					display: flex;
					align-items: center;
					height: auto;
					.el-pagination__editor{
						margin: 0 8px;
						padding: 0;
						height: auto;
						width: auto;
						.el-input__inner{
							height:auto;
							min-width: auto;
							padding: 3px 2px 2px;
							line-height: 14px;
							border: none;
							color: #30373D;
							background-color: #F6F7FA;
							font-size: 14px;
						}
					}
				}
				.el-pagination__total{
					margin-left: 10px;
					color: #86909C;
					font-size: 14px;
					line-height: 21px;
					height: 21px;
				}
				.el-pager{
					display: flex;
					align-items: center;
					.btn-quicknext,.btn-quickprev{
						height: 21px;
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: transparent;
					}
					.number{
						padding: 0;
						color: #30373D;
						line-height: 11px;
						height: auto;
						min-width: auto;
						padding: 5px 6px;
						font-size: 14px;
						margin: 0;
						font-weight: 500;
						margin-right: 10px;
						background-color: transparent;
						&:last-child{
							margin-right: 0;
						}
						&:not(.disabled).active{
							color: #30373D;
							font-weight: bolder;
							background-color: #F6F7FA;
							border-radius: 1px;
						}
					}
				}
			}
		}
	}
</style>
<style lang="scss">
@import './detail.scss';
.el-image-viewer__canvas{
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	width: 80%;
	height: 80%;
	img{
		object-fit: contain;
	}
}
.goods_content .el-carousel__indicators--horizontal{
	display: flex;
}
.review_dialog{
	.el-dialog{
		border-radius: 5px;
		margin-top: 30vh;
	}
	.el-dialog__header{
		padding: 0;
	}
	.el-dialog__body{
		padding: 14px !important;
	}
	.dialog-btns{
		margin-top: 18px;
		display: flex;
		align-items: center;
		justify-content:space-between;
		.el-upload--picture-card{
			width: 22px;
			height: 22px;
			line-height:0;
			display: flex;
			align-items: center;
			background-color: transparent;
			border: none;
			.icon-tianjiatupian{
				color: rgba(48, 55, 61, 0.80);
				font-size: 22px!important;
			}
		}
		.el-button{
			height: 37px;
			padding: 0 53px;
			border-radius: 17px;
			background-color: #F7F8FB;
			border-color: #F7F8FB;
			color: #30373D;
			font-size: 14px;
			line-height: 19px;
			font-family: "PingFang SC";
			font-weight: 600;
		}
	}
	.evaluate-main{
		background-color: #F7F8FB;
		border-radius: 5px;
		padding: 14px;
		display: flex;
		flex-direction: column;
		.el-textarea{
			min-height: 80px;
			.el-textarea__inner{
				background-color: transparent;
				border: none;
				padding: 0;
				&::placeholder{
					color: rgba(48, 55, 61, 0.50);
					font-family: "PingFang SC";
				}
			}
		}
	}
	.imgs{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		.img_item{
			width: 50px;
			height: 50px;
			position: relative;
			margin-right: 15px;
			margin-top: 10px;
			.el-image{
				width: 50px;
				height: 50px;
				img{
					cursor: pointer;
					width: 100%;
					height: 100%;
					display: block;
					border-radius: 4px;
				}
			}
			.el-icon-error{
				cursor: pointer;
				position: absolute;
				right: 0;
				top: 0;
				z-index: 10;
				transform: translate(50%,-50%);
			}
		}
	}
}
.bargin_dialog{
	.el-dialog{
		border-radius: 5px;
		margin-top: 30vh;
	}
	.el-dialog__header{
		padding: 0;
	}
	.el-dialog__body{
		padding: 14px;
	}
	.form_item{
		display: flex;
		align-items: center;
		button{
			color: #30373D;
			font-weight: 600;
			font-size: 14px;
			line-height: 19px;
			padding: 8px 53px;
			border-radius: 20px;
			background-color: #F7F8FB;
			border-color: #F7F8FB;
			font-family: "PingFang SC";
		}
		input{
			background-color: #F7F8FB;
			border-radius: 20px;
			padding: 9px 0;
			border: none;
			outline: none;
			color: #30373D;
			font-size: 14px;
			line-height: 19px;
			text-align: center;
			margin-right: 14px;
			flex: 1;
			font-family: "PingFang SC";
			&::placeholder{
				color: #30373D;
				font-size: 14px;
				line-height: 19px;
				font-family: "PingFang SC";
			}
		}
	}
}
</style>
